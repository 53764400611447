import * as React from 'react';
import styled from '@emotion/styled';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import SiteWidthWrapper from '../../components/wrappers/siteWidthWrapper';

const Heading = styled.h1`
  color: var(--blue);
  text-align: center;
`;

const SubHeading = styled.h2`
  text-align: center;
  color: white;
`;

// const HeroImage = styled.div`
// width: 100%;
// height: 600px;
// overflow: hidden;
// background: url(${HomeBG}) top left;
// background-repeat: no-repeat;
// background-size: cover;
// margin-bottom: 64px;
// `

const IndexPageServices = () => (
    <Layout>
		<Seo title='Thornhill Plumbing'
         description='Residential and Commercial Plumbing Service and Renovations' />

      {/* This page needs two boxes, one for residential, one for commercial */}

      {/* <HeroImage /> */}

      <SiteWidthWrapper>
			<Heading>Fencing Services:</Heading>
			<p>At Fence Link, we work with each customer to bring their imagination to life. There are options when it comes to chain link: galvanized, black, or white mesh are just some examples. We can offer various types of gates and fence heights as well. Whatever you envision we will try to make sure that your fence has the right curb appeal and suits your space. Most importantly is that you are provided a top quality fence that satisfied’s your needs.</p>
			<p>To ensure that your fence is durable and long lasting we dig our post holes to 3 feet deep and we mix the cement for each post.</p>
			<p>We do not limit ourselves to installing new fences; we can do repair work on existing structures as well.</p>
			<p>Our speciality is definitely chain link but we can build or help install wooden fences. Our motto is to try to help people and provide a clear picture to ensure their fencing needs are met.</p>
			<SubHeading>Auger Services:</SubHeading>
			<p>We offer auger services with the help of our Ram Rod Machine -Nimrod. It was accidentally called Nimrod and the nick name stuck.</p>
			<p>Nimrod is a 44 inch wide track machine which makes it easier to maneuver in smaller spaces. The benefit of being on tracks there is less of an impact to your lawn.</p>
			<p>We have 3 size augers: 6”, 9” and 12 “.</p>
			<SubHeading>Additional Services</SubHeading>
			<p>If you are looking to have post holes dug for your wooden fence we are available for an affordable fee. For  a reasonable rate we can set your wood posts to help you the customer obtain a straight fence.</p>
			<p>We are a small company but assured we  have WCB and commercial liability coverage.</p>
		</SiteWidthWrapper>
	</Layout>
);

export default IndexPageServices;
